<template>
  <b-card
    v-if="data"
    class="card-transaction "
    no-body
  >
    <div v-if="!isLoading">
      <b-card-header>
        <b-card-title>Evolución Mensual </b-card-title>
        <b-col
          cols="12"
          md="9"
          sm="12"
        >
          <b-form-group
            label="Año"
            label-for="año"
          >
            <v-select
              v-model="anioSelect"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="anio"
              :close-on-select="false"
              multiple
              @input="changeData"
            />
          </b-form-group>
        </b-col>

      </b-card-header>

      <b-card-body>
        <vue-apex-charts
          ref="chart"
          type="line"
          height="500"
          :options="chartOptions"
          :series="series"
        />

      </b-card-body>
    </div>

    <div
      v-else
      class="align-items-center justify-content-center  d-flex"
      :style="{height: !isLoading? '': '300px'}"
    >  <b-spinner
      style="width: 3rem; height: 3rem;"
      class="mr-1"
      label="Large Spinner"
      variant="primary"
    />
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BCol, BSpinner, BFormGroup,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import vSelect from 'vue-select'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    VueApexCharts,
    BSpinner,
    BCol,
    BFormGroup,
    vSelect,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    anios: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: true,
    },

  },
  data() {
    return {
      anio:
        [2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024],
      anioSelect: [2020, 2021, 2022, 2023, 2024],

      chartOptions: {
        chart: {
          toolbar: { show: false },
          type: 'line',
          dropShadow: {
            enabled: true,
            color: '#000',
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2,
          },
        },
        colors: [
          '#fec155',
          '#fea4a3',
          '#E9D61B',
          '#4ED54E',
          '#5587FC',
          '#44CFE9',
          '#3AB9D0',
          '#6E78CE',
          '#C973FD',
          '#D352D5',
          '#D57477',
          '#4AA049',
        ],

        grid: {
          borderColor: '#e7e7e7',
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        markers: {
          size: 1,
        },

        dataLabels: {
          enabled: true,
          formatter(val) {
            if (val > 999999) {
              return `${(val / 1000000).toFixed(1)}M`
            }
            if (val > 999) {
              return `${(val / 1000).toFixed(1)}K`
            }
            return val
            /* return `${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') */
            /*             return `${val}` */
          },

          style: {
            fontSize: '12PX',
            /*    colors: ['#304758'], */
          },
        },
        yaxis: {
          title: {
            text: 'Anios',
          },
        },
        legend: {
          position: 'top',
          horizontalAlign: 'right',
          floating: true,
          offsetY: -25,
          offsetX: -5,
        },
        /* stroke: {
          curve: 'smooth',
        }, */
        /*       title: {
          text: 'Average High & Low Temperature',
          align: 'left',
        }, */

      },
    }
  },
  computed: {
    series() {
      const showData = []
      this.data.forEach(itemA => {
        if (this.anioSelect.includes(itemA.name)) {
          showData.push(itemA)
        }
      })
      return showData
    },
  },
  watch: {
    anios: {
      immediate: true,
      handler(val) {
        console.log('soy anios', val)
        this.chartOptions = {
          ...this.chartOptions,
          xaxis: {

            categories: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sept', 'Oct', 'Nov', 'Dic'],
            title: {
              text: 'Meses',
            },
          },
        }
      },
    },
  },
  created() {
  },
  methods: {
    changeData(anio) {
      this.anioSelect = anio
      this.$emit('listen', anio)
    },
  },
}
</script>

  <style scoped>
    .loading {
      width: 100%;
      height: 500px;

      justify-content: center;
      align-items: center;
    }
  </style>
