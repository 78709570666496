export function stringToWhatsApp(str) {
  if (str === null || str === '') {
    return false
  }

  const cleanedStr = str.toString().replace(/\s/g, '').replace(/&nbsp;/g, '')
  return cleanedStr.startsWith('+51') ? cleanedStr : `+51${cleanedStr}`
}

export function removeTags(str) {
  if ((str === null) || (str === '')) {
    return ''
  }
  const res = str.toString().replace(/(<([^>]+)>)/ig, '')
  return res
}

export function extractAnio(text) {
  if (text.includes('Acumulado')) {
    const partes = text.split(' ')
    const anio = partes.find(part => /^\d{4}$/.test(part))
    return anio
  }
  return text
}
