<template>
  <section id="dashboard-analytics">
    <b-tabs content-class="mt-1">
      <!-- This tabs content will always be mounted -->
      <b-tab title="Booking">
        <b-row>
          <b-col>
            <TableroTuristasTipo
              :data="listTipoViajeTurista"
              :is-loading="loadTipoViajeTurista"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col
            lg="4"
            md="6"
          >
            <TableroPaisProcedencia
              :data="paisProcedenciaTurista"
              :is-loading="loadPaisProcedenciaTurista"
            />
            <TaleroTuristasAnioVi
              :data="listTotalTuristasAnio"
              :anios="listAnios"
              :is-loading="loadTotalTuristasAnio"
            />
          </b-col>

          <b-col
            lg="8"
            md="8"
          >
            <TableroCantidadTuristas
              :data="listCantidadTuristaMes"
              :meses="mesesSelected"
              :is-loading="loadCantidadTuristasMes"
              @listen="changeAnio"
            />

          </b-col>
        </b-row>
      </b-tab>

      <!-- This tabs content will not be mounted until the tab is shown -->
      <!-- and will be un-mounted when hidden -->
      <!--  <b-tab
        title="Booking"
        lazy
      >
        <b-alert
          variant="info"
          show
          class="mb-0"
        >
          <div class="alert-body">
            I'm lazy mounted!
          </div>
        </b-alert>
      </b-tab>
      <b-tab
        title="BCRP"
        lazy
      >
        <b-alert
          variant="info"
          show
          class="mb-0"
        >
          <div class="alert-body">
            I'm lazy mounted!
          </div>
        </b-alert>
      </b-tab> -->
      <!-- <b-tab
        title="CORPAC"
        lazy
      >
        <b-alert
          variant="info"
          show
          class="mb-0"
        >
          <div class="alert-body">
            I'm lazy mounted!
          </div>
        </b-alert>
      </b-tab> -->
      <b-tab
        id="tabmircetur"
        title="Migraciones"
        lazy
        @click="clickMe"
      >
        <h3>Superintendencia Nacional de Migraciones </h3>
        <DashMinceturP :listening="listening" />
      </b-tab>
    </b-tabs>
  <!--   <b-tooltip
      target="tabmircetur"
      title="Online!"
    /> -->
  </section>
</template>

<script>
import { BRow, BCol, BTabs, BTab, /* BAlert, */ /* BTooltip , */ VBTooltip } from 'bootstrap-vue'
/* import VueApexCharts from 'vue-apexcharts' */
/* import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue' */
import { kFormatter } from '@core/utils/filter'

import { $themeColors } from '@themeConfig'
import Ripple from 'vue-ripple-directive'
import TableroPaisProcedencia from './TableroPaisProcedencia.vue'
import TableroCantidadTuristas from './TableroCantidadTuristas.vue'
import TaleroTuristasAnioVi from './TableroTuristasAnioVi.vue'
import TableroTuristasTipo from './TableroTuristasTipo.vue'
import DashMinceturP from './mincetur/DashMinceturP.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  components: {
    /*  BTooltip, */
    BRow,
    BCol,
    TaleroTuristasAnioVi,
    TableroCantidadTuristas,
    TableroPaisProcedencia,
    TableroTuristasTipo,
    DashMinceturP,
    BTabs,
    BTab,
    /*  BAlert, */

  },
  data() {
    return {
      paisProcedenciaTurista: [],
      listCantidadTuristaMes: [],
      listTotalTuristasAnio: [],
      listTipoViajeTurista: [],
      listAnios: [],
      loadPaisProcedenciaTurista: true,
      loadCantidadTuristasMes: true,
      loadTotalTuristasAnio: true,
      loadTipoViajeTurista: true,
      listening: false,
      chartOptions3: {

        chart: {
          sparkline: { enabled: true },
          toolbar: { show: false },
        },
        grid: {
          show: false,
          padding: {
            left: 0,
            right: 0,
          },
        },
        states: {
          hover: {
            filter: 'none',
          },
        },
        colors: [
          '#ebf0f7',
          '#ebf0f7',
          $themeColors.primary,
          '#ebf0f7',
          '#ebf0f7',
          '#ebf0f7',
        ],
        plotOptions: {
          bar: {
            columnWidth: '45%',
            distributed: true,
            endingShape: 'rounded',
          },
        },
        tooltip: {
          x: { show: false },
        },
        xaxis: {
          categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
          position: 'top',
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: 'gradient',
              gradient: {
                colorFrom: '#D8E3F0',
                colorTo: '#BED1E6',
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },

        },
      },
      chartOptions: {
        chart: {
          toolbar: { show: false },
        },
        grid: {
          show: false,
          padding: {
            left: 0,
            right: 0,
          },
        },
        states: {
          hover: {
            filter: 'none',
          },
        },
        colors: [
          $themeColors.primary,
        ],
        plotOptions: {
          bar: {
            dataLabels: {
              position: 'top',
            },
            endingShape: 'rounded',
            columnWidth: '45%',
            distributed: false,
          },
        },
        dataLabels: {
          enabled: true,
          formatter(val) {
            return `${val}`
          },
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: ['#304758'],
          },
        },

        xaxis: {
          categories: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Agos', 'Sep', 'Oct', 'Nov', 'Dic'],
          position: 'top',
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: 'gradient',
              gradient: {
                colorFrom: '#D8E3F0',
                colorTo: '#BED1E6',
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: false,
          },
        },
        tooltip: {
          x: { show: false },
        },
      },
      selectedAnio: 2024,
      meses: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Agos', 'Sep', 'Oct', 'Nov', 'Dic'],
      mesesSelected: [],
    }
  },
  created() {
    this.getPaisProcedenciaTurista()
    this.getCantidadTuristasMes()
    this.getCantidadTuristasAnio()
    this.getTiposViajesTuristas()
  },
  methods: {
    kFormatter,
    clickMe() {
      this.listening = !this.listening
    },
    async  getPaisProcedenciaTurista() {
      /* store dispatch  */
      this.loadPaisProcedenciaTurista = true
      await this.$store.dispatch('tablero/PAIS_PROCEDENCIA_TURISTA').then(response => {
        this.paisProcedenciaTurista = response
        this.paisProcedenciaTurista.sort((a, b) => {
          const d = b.value - a.value
          return d
        })
      }).catch(error => {
        console.log(error)
      }).finally(() => {
        this.loadPaisProcedenciaTurista = false
      })
    },
    async getCantidadTuristasMes() {
      this.loadCantidadTuristasMes = true
      this.mesesSelected = []
      await this.$store.dispatch('tablero/CANTIDAD_TURISTAS_MES', { anio: this.selectedAnio }).then(response => {
        response.forEach(month => {
          this.listCantidadTuristaMes.push(month.value)
          this.switchOfMonthPushTomesesSelected(month.id)
        })
      }).catch(error => {
        console.log(error)
      }).finally(() => {
        this.loadCantidadTuristasMes = false
      })
    },
    async getCantidadTuristasAnio() {
      this.loadTotalTuristasAnio = true
      this.mesesSelected = []
      await this.$store.dispatch('tablero/CANTIDAD_TURISTAS_ANIO').then(response => {
        response.forEach(turist => {
          this.listTotalTuristasAnio.push(turist.value)
          this.listAnios.push(turist.id)
        })
      }).catch(error => {
        console.log(error)
      }).finally(() => {
        this.loadTotalTuristasAnio = false
      })
    },
    async getTiposViajesTuristas() {
      this.loadTipoViajeTurista = true
      this.mesesSelected = []
      await this.$store.dispatch('tablero/TIPOS_VIAJES_TURISTAS').then(response => {
        console.log(response)
        this.listTipoViajeTurista = response
        /* response.forEach(turist => {
          this.listTotalTuristasAnio.push(turist.value)
          this.listAnios.push(turist.id)
        }) */
      }).catch(error => {
        console.log(error)
      }).finally(() => {
        this.loadTipoViajeTurista = false
      })
    },
    changeAnio(anio) {
      this.selectedAnio = anio
      this.listCantidadTuristaMes = []
      this.getCantidadTuristasMes()
    },
    switchOfMonthPushTomesesSelected(valorMes) {
      switch (valorMes) {
        case 1:
          this.mesesSelected.push('Enero')
          break
        case 2:
          this.mesesSelected.push('Febrero')
          break
        case 3:
          this.mesesSelected.push('Marzo')
          break
        case 4:
          this.mesesSelected.push('Abril')
          break
        case 5:
          this.mesesSelected.push('Mayo')
          break
        case 6:
          this.mesesSelected.push('Junio')
          break
        case 7:
          this.mesesSelected.push('Julio')
          break
        case 8:
          this.mesesSelected.push('Agosto')
          break
        case 9:
          this.mesesSelected.push('Septiembre')
          break
        case 10:
          this.mesesSelected.push('Octubre')
          break
        case 11:
          this.mesesSelected.push('Noviembre')
          break
        case 12:
          this.mesesSelected.push('Diciembre')
          break

        default:
          break
      }
    },

  },
}
</script>
<!-- style div ocupar todo el ancho y alto del espacio -->
