<template>
  <b-card
    v-if="data"
    class="card-transaction "
    no-body
  >
    <div v-if="!isLoading">
      <b-card-header>
        <b-card-title>Evolución Anual </b-card-title>
        <b-col
          cols="12"
          md="3"
          sm="12"
        />
      </b-card-header>

      <b-card-body>
        <vue-apex-charts
          ref="chart"
          type="bar"
          height="500"
          :options="chartOptions"
          :series="series"
        />

      </b-card-body>
    </div>

    <div
      v-else
      class="align-items-center justify-content-center  d-flex"
      :style="{height: !isLoading? '': '300px'}"
    >  <b-spinner
      style="width: 3rem; height: 3rem;"
      class="mr-1"
      label="Large Spinner"
      variant="primary"
    />
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BCol, BSpinner,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    VueApexCharts,
    BSpinner,
    BCol,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    anios: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: true,
    },

  },
  data() {
    return {
      anio:
        ['2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021', '2022', '2023', '2024'],

      chartOptions: {
        chart: {
          toolbar: { show: false },
          /* sparkline: { enabled: true }, */
        },
        grid: {
          show: false,
          padding: {
            left: 0,
            right: 0,
          },
        },
        states: {
          hover: {
            filter: 'none',
          },
        },
        colors: [
          '#fec155',
          '#fea4a3',
          '#E9D61B',
          '#4ED54E',
          '#5587FC',
          '#44CFE9',
          '#3AB9D0',
          '#6E78CE',
          '#C973FD',
          '#D352D5',
          '#D57477',
          '#4AA049',
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            distributed: true,
            dataLabels: {
              position: 'top',
            },
            endingShape: 'rounded',
            columnWidth: '45%',

          },
        },
        /* cabecera eliminar */
        dataLabels: {
          enabled: true,
          formatter(val) {
            if (val > 999999) {
              return `${(val / 1000000).toFixed(1)}M`
            }
            if (val > 999) {
              return `${(val / 1000).toFixed(1)}K`
            }
            return val
          },
          offsetY: -20,
          style: {
            fontSize: '12PX',
            colors: ['#304758'],
          },
        },

        tooltip: {
          x: { show: false },
        },

      },
    }
  },
  computed: {
    series() {
      return [{
        name: 'Turistas',
        data: this.data,
      }]
    },
  },
  watch: {
    anios: {
      immediate: true,
      handler(val) {
        this.chartOptions = {
          ...this.chartOptions,
          xaxis: {
            labels: {
              show: false,
            },
            categories: val,
          },
          legend: {
            show: true,
            customLegendItems: val,
          },
        }
      },
    },
  },
  created() {
  },
}
</script>

  <style scoped>
    .loading {
      width: 100%;
      height: 500px;

      justify-content: center;
      align-items: center;
    }
  </style>
